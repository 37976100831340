import {defineStore} from "pinia"
import {chain, filter, keyBy, reject} from "lodash"
import axios from "axios"
import type {ICaseTask} from "@/library/models/case-task.interface"
import type {IBaseCollectionStoreState} from "@/library/stores/_base-collection"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import {LEARN_CONTENT_CATEGORY_ID, PLANNING_TIME_CATEGORY_ID} from "@/library/models/task-category.interface"
import type {ICaseMember} from "@/library/models/case-member.interface"
import type {ITempUploadedFile} from "@/library/models/temp-uploaded-file.interface"
import {useCaseStore} from "@/library/stores/case"
import type {ICaseTaskFile} from "@/library/models/case-task-file.interface"
import {useTaskContentStore} from "@/library/stores/task-content.store"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {createLogger} from "@/library/domain/logger"
import useActiveCase from "@/library/composables/useActiveCase"

export const useCaseTaskStore = defineStore("case-task", {
  state: (): IBaseCollectionStoreState<ICaseTask> => ({
    ...baseCollectionStoreState<ICaseTask>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<ICaseTask>(),
    caseTasks: ({items}): ICaseTask[] =>
      chain(items)
        .reject(({deleted_at}) => !!deleted_at)
        .sortBy("cs3_ordinal")
        .value(),

    caseTasksById(): Record<number, ICaseTask> {
      return keyBy(this.caseTasks, "id")
    },

    roadmapTasks(): ICaseTask[] {
      if (useCaseStore().isActiveCaseAfterCare) {
        return filter(this.caseTasks, ct => ct.content_category_id !== LEARN_CONTENT_CATEGORY_ID)
      } else {
        return filter(
          this.caseTasks,
          ct =>
            ct.content_category_id !== LEARN_CONTENT_CATEGORY_ID && ct.time_category_id !== PLANNING_TIME_CATEGORY_ID,
        )
      }
    },

    roadmapTasksById(): Record<number, ICaseTask> {
      return keyBy(this.roadmapTasks, "id")
    },

    learnTasks(): ICaseTask[] {
      return filter(this.caseTasks, {content_category_id: LEARN_CONTENT_CATEGORY_ID})
    },

    plannerTasks(): ICaseTask[] {
      return filter(this.caseTasks, {time_category_id: PLANNING_TIME_CATEGORY_ID})
    },

    loaded: ({items}): boolean => items !== null,
  },
  actions: {
    ...baseCollectionStoreActions<ICaseTask>(),

    async pullCaseTasks(caseId: ICaseTask["case_id"], useCache = true) {
      const caseMemberStore = useCaseMembersStore()
      await caseMemberStore.fetchMembers(useActiveCase().id)

      if (caseMemberStore.isAuthMemberASuccessor && !caseMemberStore.isAuthMemberASuccessorCollaborator) {
        createLogger().debug(
          "client/enduser/src/library/stores/case-task.ts",
          `avoid fetching tasks for limited access successors as this would trigger a policy
                  exception in the backend. Currently Lim access successors do not have access to the
                  roadmap, and so guarding in this manner is more centralized than including a guard any
                  time we call a fetch on tasks.`,
        )
        return
      }

      return this.cachedFetch(async () => {
        const [tasksResponse, contentResponse] = await Promise.all([
          axios.get(`/v3/enduser/cases/${caseId}/tasks`),
          useTaskContentStore().pull(caseId),
        ])
        return tasksResponse.data.data
      }, useCache)
    },
    async fetchCaseTask(caseId: ICaseTask["case_id"], caseTaskId: ICaseTask["id"]) {
      const response = await axios.get(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}`)
      this.upsert(response.data.data)
      return response.data.data
    },
    async update(
      props:
        | Partial<Pick<ICaseTask, "seen_at" | "downloaded_at">>
        | {files: (ITempUploadedFile | ICaseTaskFile)[] | null},
      caseTaskId: ICaseTask["id"],
      caseId: ICaseTask["case_id"],
    ): Promise<ICaseTask> {
      const response = await axios.put(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}`, props)
      this.upsert(response.data.data)
      return response.data.data
    },
    async create(
      props: Partial<Pick<ICaseTask, "seen_at" | "downloaded_at" | "files" | "assignee_case_member_id">>,
      caseId: ICaseTask["case_id"],
    ): Promise<ICaseTask> {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks`, props)
      this.upsert(response.data.data)
      return response.data.data
    },
    async delete(caseTaskId: ICaseTask["id"], caseId: ICaseTask["case_id"]): Promise<ICaseTask> {
      const response = await axios.delete(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}`)
      this.upsert(response.data.data)
      return response.data.data
    },
    async addFile(file: ITempUploadedFile | ICaseTaskFile, caseTask: ICaseTask) {
      const files: (ITempUploadedFile | ICaseTaskFile)[] = caseTask.files || []
      return this.update({files: files.concat(file)}, caseTask.id, caseTask.case_id)
    },
    async removeFile({id}: ICaseTaskFile, caseTask: ICaseTask) {
      const files: (ITempUploadedFile | ICaseTaskFile)[] = caseTask.files || []
      return this.update({files: reject(files, {id})}, caseTask.id, caseTask.case_id)
    },
    async markAsSeen(caseTaskId: ICaseTask["id"], caseId: ICaseTask["case_id"]) {
      return this.update({seen_at: new Date().toISOString()}, caseTaskId, caseId)
    },
    async markAsDownloaded(caseTaskId: ICaseTask["id"], caseId: ICaseTask["case_id"]) {
      await this.update({downloaded_at: new Date().toISOString()}, caseTaskId, caseId)
    },
    async complete(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/complete`)
      this.upsert(response.data.data)
    },
    async todo(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/todo`)
      this.upsert(response.data.data)
    },
    async inProgress(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/in-progress`)
      this.upsert(response.data.data)
    },
    async defer(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/defer`)
      this.upsert(response.data.data)
    },
    async prepared(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.post(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/prepared`)
      this.upsert(response.data.data)
    },
    async assign(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"], memberId: ICaseMember["id"]) {
      const response = await axios.put(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/assignee`, {
        case_member_id: memberId,
      })
      this.upsert(response.data.data)
    },
    async unassign(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"]) {
      const response = await axios.delete(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/assignee`)
      this.upsert(response.data.data)
    },
    async star(caseTaskId: ICaseTask["id"], caseId: ICaseTask["id"], isStarred: ICaseTask["starred"]) {
      const response = await axios.patch(`/v3/enduser/cases/${caseId}/tasks/${caseTaskId}/toggle-star`, {
        starred: isStarred,
      })
      this.upsert(response.data.data)
    },
    calculateLearnTaskReadTime(learnTask: ICaseTask) {
      const articleWordCount = useTaskContentStore()
        .getContentForCaseTask(learnTask, "instructions")
        .split(/\s+/).length
      const presumedReadingSpeed = 200

      const readDuration = Math.ceil(articleWordCount / presumedReadingSpeed)

      return Math.max(2, readDuration)
    },
  },
})
