import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const ID_DOCUMENT_PATH = "id_document"

export const ID_DOCUMENT_LABEL_MAP: LabelMap = new Map([
  ["id_document[*].type", ""],
  ["id_document[*].name", ""],
  ["id_document[*].id", ""],
  ["id_document[*].attachment", ""],
  ["id_document[*].notes", ""],
])
