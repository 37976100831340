import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const WILL_PATH = "will"

// Owner
export const WILL_LABEL_MAP: LabelMap = new Map([
  ["will.location_hint", ""],
  ["will.date_created", ""],
  ["will.last_updated", ""],
  ["will.attachment", ""],
  ["will.notes", ""],
])
