import type {IUser} from "@/library/models/user.interface"
import type {ICase} from "@/library/models/case.interface"
import type {ICaseMember} from "@/library/models/case-member.interface"

export enum CaseCollaborationRequestStatuses {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DRAFT = "draft",
  BOUNCED = "bounced",
  EXPIRED = "expired",
  REJECTED = "rejected",
}

export interface ICaseCollaborationRequest {
  id: number
  case_id: ICase["id"]
  case_member_id: ICaseMember["id"]
  user_id: IUser["id"]
  status: CaseCollaborationRequestStatuses
}
