import type {ICaseTask} from "@/library/models/case-task.interface"
import {
  EXECUTOR_ASSISTANT_LEARN_ARTICLE_NAME,
  EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NAME,
} from "@/executor-assistant/routes"
import {useTaskCategoryStore} from "@/library/stores/task-category"
import {useCaseTaskStore} from "@/library/stores/case-task"
import {LEARN_CONTENT_CATEGORY_ID} from "@/library/models/task-category.interface"
import {createLogger} from "@/library/domain/logger"
import type {LocationQueryRaw, RouteLocationRaw} from "vue-router"
import {find} from 'lodash'

export default function useCaseTaskRouting() {
  return {
    createRouteFor(caseTaskId: ICaseTask["id"], query?: LocationQueryRaw): undefined | RouteLocationRaw {
      const caseTaskStore = useCaseTaskStore()
      if (!caseTaskStore.isLoaded) {
        createLogger().warn("library/composables/useCaseTaskRouting", "caseTaskStore is not loaded", {
          extra: {caseTaskId},
        })
        return
      }

      const caseTask = caseTaskStore.caseTasksById[caseTaskId]
      if (!caseTask) {
        return
      }

      const isLearnTask = caseTask.content_category_id === LEARN_CONTENT_CATEGORY_ID
      return isLearnTask
        ? {name: EXECUTOR_ASSISTANT_LEARN_ARTICLE_NAME, params: {caseTaskId, caseId: caseTask.case_id}, query}
        : {name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NAME, params: {caseTaskId, caseId: caseTask.case_id}, query}
    },
    createRouteForTaskId(taskId: ICaseTask["task_id"]) {
      const caseTask = find(useCaseTaskStore().caseTasksById, caseTask => caseTask.task_id === taskId)
      if (!caseTask) {
        createLogger().info(
          "executor-assistant/case-tasks/CaseTaskLayout",
          "Unable to find case task linked to from instructions.",
          {extra: {taskId}},
        )
        return null
      }
      return this.createRouteFor(caseTask.id)
    }
  }
}
