import analytics from "@/setup/segment"
import type {IAuthUser} from "@/library/models/auth-user.interface"
import type {ICaseTask} from "@/library/models/case-task.interface"
import type {IQuestionnaire} from "@/library/domain/questionnaires/questionnaire.interface"
import useAuthUser from "@/library/composables/useAuthUser"
import {useCaseStore} from "@/library/stores/case"
import type {IRoadmapFilters} from "@/library/models/case-member.interface"
import type {ICaseTaskFile} from "@/library/models/case-task-file.interface"
import type {ITempUploadedFile} from "@/library/models/temp-uploaded-file.interface"
import type {ITask} from "@/library/models/task.interface"
import {LEARN_CONTENT_CATEGORY_ID} from "@/library/models/task-category.interface"
import {ref} from "vue"

export function _track(eventName: string, properties: any) {
  analytics?.track(eventName, {...properties, ...createActiveUserTrackingPointsForEvent()})
}

export function createActiveUserTrackingPointsForEvent() {
  const user = useAuthUser()
  const activeCase = useCaseStore().activeCase // avoiding `useActiveCase()` because it raises on absence
  return {
    user_id: user.id,
    case_id: activeCase?.id,
    org_id: activeCase?.org_id,
  }
}

export const identifyUser = (user: IAuthUser) =>
  analytics?.identify(
    `${user.id}`,
    {
      first_name: `${user.first_name}`,
      last_name: `${user.last_name}`,
      email: `${user.email}`,
      ...createActiveUserTrackingPointsForEvent(),
    },
    {
      integrations: {
        Intercom: {
          user_hash: user.intercom_hash,
        },
      },
    },
  )

export const taskViewedForFirstTime = (caseTask: ICaseTask) =>
  _track("Task Viewed", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_task_source: caseTask.source,
    first_task_view: true,
    is_learn_task: caseTask.content_category_id === LEARN_CONTENT_CATEGORY_ID,
  })

export const taskViewed = (caseTask: ICaseTask, referringTask: ICaseTask | null = null) =>
  _track("Task Viewed ", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_task_source: caseTask.source,
    first_task_view: false,
    is_learn_task: caseTask.content_category_id === LEARN_CONTENT_CATEGORY_ID,
    referring_task: referringTask
      ? {
          case_task_id: referringTask.id,
          task_id: referringTask.task_id,
        }
      : null,
  })

export const taskClosed = (caseTask: ICaseTask) =>
  _track("Task Closed ", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_id: caseTask.case_id,
    case_task_source: caseTask.source,
  })

export const taskMarkedCompleted = (caseTask: ICaseTask) =>
  _track("Task Completed", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_id: caseTask.case_id,
    case_task_source: caseTask.source,
  })

export const taskAttachmentUploaded = (caseTask: ICaseTask, file: ICaseTaskFile | ITempUploadedFile) =>
  _track("Task Attachment Uploaded", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_id: caseTask.case_id,
    case_task_file: file,
  })

export const taskAttachmentDeleted = (caseTask: ICaseTask, file: ICaseTaskFile) =>
  _track("Task Attachment Deleted", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_id: caseTask.case_id,
    case_task_file: file,
  })

export const taskAttachmentDownloaded = (caseTask: ICaseTask, file: ICaseTaskFile) =>
  _track("Task Attachment Deleted", {
    case_task_id: caseTask.id,
    task_id: caseTask.task_id,
    case_id: caseTask.case_id,
    case_task_file: file,
  })

export const questionnaireCompleted = (questionnaire: IQuestionnaire | null) =>
  _track("Questionnaire Completed", {
    questionnaire_id: questionnaire?.id,
    questionnaire_title: questionnaire?.title,
    questionnaire_type: questionnaire?.type,
  })

export const trackRoadmapFiltered = (filters: IRoadmapFilters) => _track("Roadmap filtered", {filters})

export const appSearchQueried = (term: string, referrer: string) =>
  _track("AppSearch Queried", {
    search_term: term,
    search_referrer: referrer,
  })

export const appSearchNavigated = (term: string, referrer: string, target: string) =>
  _track("AppSearch Navigated", {
    search_term: term,
    search_referrer: referrer,
    search_result_target: target,
  })

export const additionalToolsDownloaded = (name: "assets_and_liability_inventory" | "executor_log") =>
  _track("AdditionalTools Downloaded", {tool_name: name})

export const trackGriefNewsletterSubscribed = () => _track("Grief Newsletter Subscribed", {})

export const trackGriefGroupSignup = () => _track("GriefGroup Signup Navigated", {})
export const griefRecordingPlayed = (recording: string) => {
  _track("GriefRecording played", {title: recording})
}

export const griefPodcastNavigated = (podcast: string) => {
  _track("GriefPodcast Navigated", {title: podcast})
}

export const griefCommunityNavigated = (community: string) => {
  _track("GriefCommunity Navigated", {title: community})
}
