import {find, keyBy} from "lodash"
import type {Dictionary} from "lodash"
import type {Store} from "pinia"
import type {Raw} from "vue"
import {createLogger} from "@/library/domain/logger"

// TYPES
export type DbRecord = {id: string | number}
export type IBaseCollectionStoreState<T extends DbRecord> = {
  items: null | T[]
  itemsFetchPromise: null | Promise<null | T[]>
}
export type IBaseCollectionStoreGetters<T extends DbRecord> = {
  byId: (state: IBaseCollectionStoreState<T>) => Dictionary<T>
  numItems: (state: IBaseCollectionStoreState<T>) => number
  isLoaded: (state: IBaseCollectionStoreState<T>) => boolean
}
export type IBaseCollectionStoreActions<T extends DbRecord> = {
  upsert: (item: T | null) => T | null
  upsertMany: (items: T[], resetItems?: boolean) => T[]
  remove: (id: T["id"]) => T | null
  cachedFetch: (cb: () => Promise<T[]>, useCache?: boolean) => Promise<null | T[]>
}
export type IBaseCollectionStore<T extends DbRecord> = Store<
  string,
  Raw<IBaseCollectionStoreState<T>>,
  IBaseCollectionStoreGetters<T>,
  IBaseCollectionStoreActions<T>
>

// STATE
export function baseCollectionStoreState<T extends DbRecord>(): IBaseCollectionStoreState<T> {
  return {
    items: null,
    itemsFetchPromise: null,
  }
}

// GETTERS
export function baseCollectionStoreGetters<T extends DbRecord>(): IBaseCollectionStoreGetters<T> {
  return {
    byId: (state: IBaseCollectionStoreState<T>) => {
      return keyBy(state.items, "id")
    },
    numItems: (state: IBaseCollectionStoreState<T>) => {
      return state.items?.length || 0
    },
    isLoaded: (state: IBaseCollectionStoreState<T>) => {
      return state.items !== null
    },
  }
}

// ACTIONS
export function baseCollectionStoreActions<T extends DbRecord>(): IBaseCollectionStoreActions<T> {
  return {
    upsert: function (this: IBaseCollectionStore<T>, item: T | null): T | null {
      if (!item) {
        return null
      }

      const t = find(this.items, (t: T) => t.id === item.id) ?? null
      if (t) {
        Object.assign(t, item)
      } else {
        if (this.items) {
          this.items.push(item)
        } else {
          this.items = [item]
        }
      }

      return item
    },
    upsertMany: function (this: IBaseCollectionStore<T>, items: T[], resetItems: boolean = true) {
      if (resetItems)  {
        this.items = []
      }
      for (const item of items) {
        this.upsert(item)
      }

      return items
    },
    remove: function (this: IBaseCollectionStore<T>, id: T["id"]) {
      const t = find(this.items, (t: T) => t.id === id) ?? null
      this.items = this.items?.filter(t => t.id !== id) ?? null
      return t
    },
    /**
     * Use this function when fetching `items` for this store if you want subsequent requests to just return
     * the previously fetched items instead of making a network request.
     *
     * This function will also prevent more than one network request at a time, if multiple components call the
     * fetch action at the same time.
     *
     * @param callback your code to execute to fetch data from the server.
     * @param useCache whether or not to use existing cache or forcefully refetch
     */
    cachedFetch: async function (
      this: IBaseCollectionStore<T>,
      callback: () => Promise<T[]>,
      useCache = true,
    ): Promise<null | T[]> {
      if (this.itemsFetchPromise && useCache) {
        // return previously fetched items if we have it
        // existence of promise indicates that there is a pending request, so return the promise if we have it
        return this.itemsFetchPromise
      }

      // no cache or skipping, let's execute the user code
      this.itemsFetchPromise = callback()

      try {
        return (this.items = await this.itemsFetchPromise)
      } catch (e) {
        createLogger().warn("library/stores/_base-collection", "Failed to perform cached fetch", {extra: {e}})
        this.itemsFetchPromise = null // allow retry upon failure
        return null
      }
    },
  }
}
