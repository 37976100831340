import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const SPOUSE_PERSONAL_INFO_LABEL_MAP: LabelMap = new Map([
  ["spouse.name", ""],
  ["spouse.maiden_name", ""],
  ["spouse.name_at_birth", ""],
  ["spouse.date_of_birth", ""],
  ["spouse.place_of_birth", ""],

  ["spouse.name_social_insurance", ""],
  ["spouse.social_insurance_number", ""],
])

export const SPOUSE_CONTACT_INFO_LABEL_MAP: LabelMap = new Map([
  ["spouse.phone_number", ""],
  ["spouse.address", ""],
])

export const SPOUSE_MARRIAGE_INFO_LABEL_MAP: LabelMap = new Map([
  ["spouse.date_of_marriage", ""],
  ["spouse.marriage_location", ""],
  ["spouse.marriage_performed_by_name", ""],
  ["spouse.witness_1", ""],
  ["spouse.witness_2", ""],
  ["spouse.reason_for_no_marriage_certificate", ""],
])

export const SPOUSE_IMPORTANT_DATES_LABEL_MAP: LabelMap = new Map([
  ["spouse.date_started_living_with_spouse", ""],
  ["spouse.date_last_lived_together", ""],
  ["spouse.date_of_legal_separation", ""],
  ["spouse.date_of_divorce", ""],
])

export const SPOUSE_PARENT_INFO_LABEL_MAP: LabelMap = new Map([
  ["spouse.mothers_name", ""],
  ["spouse.fathers_name", ""],
])
