import {defineStore} from "pinia"

import {find, isEqual, uniqueId} from "lodash"
import type {INotification} from "@/library/models/notification.interface."
import {NotificationType} from "@/library/models/notification.interface."
import type {ValidationErrors} from "@/library/composables/useErrorHandler"
import i18next from "i18next"

export const SUCCESS_AUTOCLOSE_DELAY_MS = 2500

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    notifications: [] as INotification[],
  }),

  actions: {
    success(message: string = i18next.t("success"), options?: Partial<INotification>) {
      this.notify({
        message,
        type: NotificationType.Success,
        auto_close: SUCCESS_AUTOCLOSE_DELAY_MS,
        ...options,
      })
    },

    error(message: string, options?: Partial<INotification>) {
      this.notify({
        message,
        type: NotificationType.Error,
        auto_close: 7000,
        ...options,
      })
    },

    validationError(message: string, errors: ValidationErrors, options?: Partial<INotification>) {
      this.notify({
        message,
        type: NotificationType.ValidationError,
        auto_close: 7000,
        ...options,
        meta: {errors},
      })
    },

    warning(message: string, options?: Partial<INotification>) {
      this.notify({
        message,
        type: NotificationType.Warning,
        auto_close: 7000,
        ...options,
      })
    },

    info(message: string, options?: Partial<INotification>) {
      this.notify({
        message,
        type: NotificationType.Info,
        auto_close: 7000,
        ...options,
      })
    },

    notify(n: Partial<INotification>) {
      const fullNotification = createNotificationWith(n)
      this.notifications.push(fullNotification)

      if (typeof fullNotification.auto_close === "number") {
        setTimeout(() => this.removeNotification(fullNotification), fullNotification.auto_close)
      }
    },

    removeNotification(notification: INotification) {
      if (!find(this.notifications, notification)) {
        return
      }
      this.notifications = this.notifications.filter(n => !isEqual(n, notification))
    },
  },
})

export function createNotificationWith(n: Partial<INotification>) {
  return {
    message: n.message ?? "",
    type: n.type ?? NotificationType.Success,
    id: n.id ?? uniqueId("notification-"),
    auto_close: n.auto_close === undefined ? SUCCESS_AUTOCLOSE_DELAY_MS : n.auto_close,
    meta: n.meta,
    html: n.html ?? false,
  }
}
