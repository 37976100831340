import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const INSURANCE_PATH = "insurance"

export const INSURANCE_LABEL_MAP: LabelMap = new Map([
  ["insurance[*].type", ""],
  ["insurance[*].name", ""],
  ["insurance[*].id", ""],
  ["insurance[*].attachment", ""],
  ["insurance[*].notes", ""],
])
