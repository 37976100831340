import type {RouteLocation} from "vue-router"
import {
  EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME,
  EXECUTOR_ASSISTANT_CONTACTS_NAME,
  EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME,
  EXECUTOR_ASSISTANT_GRIEF_CARE_NAME,
  EXECUTOR_ASSISTANT_PLANNER_NAME,
  EXECUTOR_ASSISTANT_ROADMAP_NAME,
} from "@/executor-assistant/routes"
import {useCaseStore} from "@/library/stores/case"
import {find, startsWith} from "lodash"
import {CaseStatus} from "@/library/models/case.interface"
import useCaseTaskRouting from "@/library/composables/useCaseTaskRouting"
import {useCaseTaskStore} from "@/library/stores/case-task"

export const magicLinks = [
  {
    path: "/task/:taskId",
    beforeEnter: [taskMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/estate-representatives",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/planner",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/roadmap",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/additional-tools",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/care",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-task/:taskId",
    beforeEnter: [taskMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-vault/:section",
    beforeEnter: [vaultMagicLinksBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-roadmap",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-planner",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-contacts",
    beforeEnter: [genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
]

async function taskMagicLinkBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {path: "not-found"}
  }
  await useCaseTaskStore().pullCaseTasks(caseId)

  return useCaseTaskRouting().createRouteForTaskId(+to.params["taskId"]) ?? {path: "not-found"}
}

async function vaultMagicLinksBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {path: "not-found"}
  }

  return {name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME, params: {caseId, section: to.params["section"]}}
}

async function genericMagicLinkBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {path: "not-found"}
  }
  switch (to.path) {
    case "/planner":
      return {name: EXECUTOR_ASSISTANT_PLANNER_NAME, params: {caseId}}
    case "/roadmap":
      return {name: EXECUTOR_ASSISTANT_ROADMAP_NAME, params: {caseId}}
    case "/additional-tools":
      return {name: EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME, params: {caseId}}
    case "/care":
      return {name: EXECUTOR_ASSISTANT_GRIEF_CARE_NAME, params: {caseId}}

    // LP Routes
    case "/lp-roadmap":
      return {name: EXECUTOR_ASSISTANT_ROADMAP_NAME, params: {caseId}}
    case "/lp-planner":
      return {name: EXECUTOR_ASSISTANT_PLANNER_NAME, params: {caseId}}
    case "/lp-contacts":
      return {name: EXECUTOR_ASSISTANT_CONTACTS_NAME, params: {caseId}}
  }

  return {path: "not-found"}
}

async function _findCaseIdMagicLink(to: RouteLocation, from: RouteLocation) {
  if (from.params.caseId) {
    return +from.params.caseId
  }
  const caseStore = useCaseStore()
  await caseStore.fetchCases()

  if (startsWith(to.path, "/lp-")) {
    return find(caseStore.items, {status: CaseStatus.STATUS_PRE_PLANNER})?.id
  }

  const firstEaCase = find(caseStore.items, {status: CaseStatus.STATUS_AFTER_CARE})
  return firstEaCase?.id
}
