<template>
  <Modal ref="modalRef" @shown="onShown()" @hidden="onHidden()">
    <template #title-text v-if="confirm.prompt?.title">
      <div class="px-sm-4">{{ confirm.prompt?.title }}</div>
    </template>

    <div class="px-sm-4 pb-3">
      <div class="mb-4 is-pii">
        <p v-if="confirm.prompt?.message" v-html="confirm.prompt?.message"></p>
        <p v-else>{{ $t("confirm_section.title") }}</p>
      </div>
    </div>

    <template #footer>
      <button @click="onConfirm()" ref="confirmBtnRef" class="btn btn-primary">
        {{ confirm.prompt?.confirmationLabel || $t("confirm_section.confirm_cta") }}
      </button>
      <button @click="onCancel()" class="btn btn-outline-dark me-3">{{ $t("form.cancel") }}</button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/library/components/modals/Modal.vue"
import {useConfirm} from "@/library/stores/confirm"
import {nextTick, onMounted, ref} from "vue"

const confirm = useConfirm()
const modalRef = ref<typeof Modal | null>(null)
const confirmBtnRef = ref<HTMLButtonElement | null>(null)
const confirmed = ref(false)
onMounted(() => {
  nextTick(() => modalRef.value?.show())
})
const onShown = () => {
  confirmBtnRef.value?.focus()
}
const onCancel = () => {
  confirmed.value = false
  modalRef.value?.hide()
}
const onConfirm = () => {
  confirmed.value = true
  modalRef.value?.hide()
}
const onHidden = () => {
  if (confirmed.value === true) {
    confirm.accept()
  } else {
    confirm.reject()
  }
}
</script>
