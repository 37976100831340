import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const FINANCES_PATH = "financial_information"

export const FINANCES_LABEL_MAP: LabelMap = new Map([
  ["financial_information[*].type", ""],
  ["financial_information[*].name", ""],
  ["financial_information[*].id", ""],
  ["financial_information[*].attachment", ""],
  ["financial_information[*].notes", ""],
])
