import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const CONTACTS_LABEL_MAP: LabelMap = new Map([
  ["contact[*].name", ""],
  ["contact[*].date_of_birth", ""],

  ["contact[*].gender", ""],
  ["contact[*].maiden_name", ""],

  ["contact[*].professional_relationship", ""],
  ["contact[*].relationship", ""],

  ["contact[*].email.email_address", ""],
  // ["contact[*].email.type", ""],
  ["contact[*].address.address_location", ""],
  // ["contact[*].address.type", ""],
  ["contact[*].phone.phone_number", ""],
  // ["contact[*].phone.type", ""],

  ["contact[*].notes", ""],
])