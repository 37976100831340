import {watch} from "vue"
import {useCaseStore} from "@/library/stores/case"
import {useUserStore} from "@/library/stores/user"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {createLogger} from "@/library/domain/logger"

export default function setupClarity() {
  if (!import.meta.env.VITE_CLARITY_API_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/setupClarity.ts",
      `Skipping initialization of Clarity due to absent VITE_CLARITY_API_KEY`,
    )
    return
  }

  // @ts-ignore
  // prettier-ignore
  // eslint-disable-next-line no-extra-semi
  ;(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", import.meta.env.VITE_CLARITY_API_KEY);

  watch(
    () => useCaseStore().activeCase,
    activeCase => {
      if ("clarity" in window) {
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'is_test', activeCase?.is_test ? 'true' : 'false')
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'case_id', `${activeCase?.id}`)
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'case_status', `${activeCase?.status}`)
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'is_transitioned_case', activeCase?.meta.transitioned_to_aftercare_at ? 'true' : 'false')
      }
    },
  )

  watch(
    () => useUserStore().user,
    user => {
      if ("clarity" in window) {
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'user_id', `${user?.id}`)
      }
    },
  )

  watch(
    () => useCaseMembersStore().authUserMember,
    authUserMember => {
      if ("clarity" in window) {
        // @ts-ignore
        // prettier-ignore
        window.clarity('set', 'user_role', `${authUserMember?.role}`)
      }
    },
  )
}
