import {defineStore} from "pinia"
import axios from "axios"

import type {ICase} from "@/library/models/case.interface"
import {CaseStatus, CaseStatusWithTransitioned} from "@/library/models/case.interface"
import {assign, chain, cloneDeep, defaults, find} from "lodash"
import type {IOwnershipTransferRequest} from "@/library/models/ownership-transfer-request.interface"
import type {IBaseCollectionStoreState} from "@/library/stores/_base-collection"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"

interface State extends IBaseCollectionStoreState<ICase> {
  activeCaseId: number | null
}

export const useCaseStore = defineStore("case", {
  state: (): State => ({
    ...baseCollectionStoreState<ICase>(),
    activeCaseId: null,
  }),
  getters: {
    ...baseCollectionStoreGetters<ICase>(),
    activeCase(state): ICase | null {
      if (!state.items || !state.activeCaseId) {
        return null
      }
      return find(state.items, {id: state.activeCaseId}) || null
    },
    isActiveCaseAfterCare(): boolean {
      return (
        !!this.activeCase &&
        (this.activeCase.status === CaseStatus.STATUS_AFTER_CARE ||
          this.activeCase.status === CaseStatus.STATUS_AFTER_CARE_LOCKED)
      )
    },
    isActiveCasePreplanner(): boolean {
      return (
        !!this.activeCase &&
        (this.activeCase.status === CaseStatus.STATUS_PRE_PLANNER ||
          this.activeCase.status === CaseStatus.STATUS_PRE_PLANNER_LOCKED)
      )
    },
    isActiveCaseTransitioned(): boolean {
      return !!this.activeCase!.meta?.transitioned_to_aftercare_at
    },

    activeCaseStatusWithTransitioned(): undefined | CaseStatusWithTransitioned {
      const activeCase = this.activeCase
      if (!activeCase) {
        return
      }

      return this.isActiveCasePreplanner
        ? CaseStatusWithTransitioned.STATUS_PRE_PLANNER
        : this.isActiveCaseTransitioned
          ? CaseStatusWithTransitioned.STATUS_AFTER_CARE_TRANSITIONED
          : CaseStatusWithTransitioned.STATUS_AFTER_CARE
    },

    caseLikeRenderContext(): ICase {
      // inflate a "location" entity based on the business name when location absent for rendering via usePersonalizeWithActiveCaseName()
      const location = this.activeCase?.location?.name
        ? this.activeCase?.location
        : {id: null, name: this.activeCase?.org?.name}

      return assign({}, this.activeCase, {location})
    },
  },
  actions: {
    ...baseCollectionStoreActions<ICase>(),
    async fetchCases() {
      return this.cachedFetch(async () => {
        const response = await axios.get<{data: ICase[]}>("/v3/enduser/cases")
        return response.data.data
      })
    },
    setActiveCase(caseId: ICase["id"] | null) {
      this.activeCaseId = caseId
    },
    async requestOwnershipTransfer(caseId: number, data: IOwnershipTransferRequest) {
      const response = await axios.post<{data: ICase}>(`/v3/enduser/cases/${caseId}/ownership-transfer-requests`, data)
      this.upsert(response.data.data)
    },
    async requestFreeLegacyPlanner(caseId: number, status: number) {
      const data = {
        source_case_id: caseId,
        status: status,
      }
      const response = await axios.post("/v3/enduser/cases/self-signup", data)
      this.upsert(response.data.data)

      return response.data.data
    },
  },
})

export const isCaseAfterCare = (dCase: ICase): boolean =>
  dCase.status === CaseStatus.STATUS_AFTER_CARE || dCase.status === CaseStatus.STATUS_AFTER_CARE_LOCKED