import type {RouteLocation} from "vue-router"
import {TSectionTypes} from "@/library/domain/app-fields/label-maps"

export const EXECUTOR_ASSISTANT_BASE_NAME = "executor-assistant-base"
export const EXECUTOR_ASSISTANT_HOME_NAME = "executor-assistant-home"
export const EXECUTOR_ASSISTANT_PLANNER_CONTAINER_NAME = "executor-assistant-planner-container"
export const EXECUTOR_ASSISTANT_PLANNER_NAME = "executor-assistant-planner"

export const EXECUTOR_ASSISTANT_PLANNER_SECTION_NAME = "executor-assistant-planner-section"
export const EXECUTOR_ASSISTANT_ROADMAP_ROOT_NAME = "executor-assistant-roadmap-root"
export const EXECUTOR_ASSISTANT_ROADMAP_NAME = "executor-assistant-roadmap"
export const EXECUTOR_ASSISTANT_ROADMAP_TIME_CATEGORY_NAME = "executor-assistant-roadmap-time-category"
export const EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NAME = "executor-assistant-roadmap-case-task"
export const EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_EDIT_NAME = "executor-assistant-roadmap-case-task-edit"
export const EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_ACTIONS_NAME = "executor-assistant-roadmap-case-task-mobile-actions"
export const EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NEW_NAME = "executor-assistant-roadmap-v2-case-task-new"
export const EXECUTOR_ASSISTANT_ROADMAP_PAGE_CONTENT = "executor-assistant-roadmap-page-content"
export const EXECUTOR_ASSISTANT_COLLABORATORS_NAME = "executor-assistant-collaborators"
export const EXECUTOR_ASSISTANT_CONTACTS_NAME = "executor-assistant-contacts"
export const EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME = "executor-assistant-additional-tools"
export const EXECUTOR_ASSISTANT_LEARN_NAME = "executor-assistant-learn"
export const EXECUTOR_ASSISTANT_LEARN_ARTICLE_NAME = "executor-assistant-learn-article"
export const EXECUTOR_ASSISTANT_GRIEF_CARE_NAME = "executor-assistant-grief-care"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_CONTAINER_NAME = "executor-assistant-digital-vault-container"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_NAME = "executor-assistant-digital-vault"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME = "executor-assistant-digital-vault-section"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_FUNERAL_NAME = "executor-assistant-digital-vault-funeral-section"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_PROFILE_NAME = "executor-assistant-digital-vault-profile-section"
export const EXECUTOR_ASSISTANT_DIGITAL_VAULT_LEGAL_NAME = "executor-assistant-digital-vault-legal-section"
export const EXECUTOR_ASSISTANT_FEEDBACK_NAME = "executor-assistant-feedback"

export const EXECUTOR_ASSISTANT_ROUTES = [
  {
    path: "home",
    alias: "",
    children: [
      {
        path: "",
        name: EXECUTOR_ASSISTANT_HOME_NAME,
        component: () => import("@/executor-assistant/dashboard/pages/DashboardPage.vue"),
      },
    ],
  },
  {
    path: "planner",
    name: EXECUTOR_ASSISTANT_PLANNER_CONTAINER_NAME,
    children: [
      {
        path: "",
        name: EXECUTOR_ASSISTANT_PLANNER_NAME,
        component: () => import("@/executor-assistant/task-planner/components/TaskPlanner.vue"),
      },
      {
        path: ":flowUuid",
        name: EXECUTOR_ASSISTANT_PLANNER_SECTION_NAME,
        component: () => import("@/executor-assistant/task-planner/components/TaskPlanner.vue"),
        meta: {
          scrollToTop: false,
        },
      },
    ],
  },
  {
    path: "roadmap",
    name: EXECUTOR_ASSISTANT_ROADMAP_ROOT_NAME,
    component: () => import("@/executor-assistant/case-tasks/CaseTaskLayout.vue"),
    children: [
      {
        path: "",
        name: EXECUTOR_ASSISTANT_ROADMAP_NAME,
        component: () => import("@/executor-assistant/case-tasks/CaseTaskGlobal.vue"),
      },
      {
        path: "new",
        name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NEW_NAME,
        component: () => import("@/executor-assistant/case-tasks/view/CaseTaskPage.vue"),
        props: (route: RouteLocation) => ({
          isEdit: true,
        }),
      },
      {
        path: "tasks/:caseTaskId",
        children: [
          {
            path: "",
            name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NAME,
            component: () => import("@/executor-assistant/case-tasks/view/CaseTaskPage.vue"),
            props: true,
          },
          {
            path: "edit",
            name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_EDIT_NAME,
            component: () => import("@/executor-assistant/case-tasks/view/CaseTaskPage.vue"),
            props: (route: RouteLocation) => ({
              caseTaskId: route.params.caseTaskId,
              isEdit: true,
            }),
          },
          {
            path: "actions",
            name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_ACTIONS_NAME,
            component: () => import("@/executor-assistant/case-tasks/view/detail/CaseTaskActions.vue"),
            props: (route: RouteLocation) => ({
              caseTaskId: +route.params.caseTaskId,
            }),
          },
        ],
      },
      {
        path: ":timeCategorySlug",
        children: [
          {
            path: "",
            name: EXECUTOR_ASSISTANT_ROADMAP_TIME_CATEGORY_NAME,
            redirect: {name: EXECUTOR_ASSISTANT_ROADMAP_NAME},
          },

          {
            path: ":contentCategorySlug",
            children: [
              {
                path: "",
                redirect: {name: EXECUTOR_ASSISTANT_ROADMAP_NAME},
              },
              {
                path: ":caseTaskId",
                redirect: {name: EXECUTOR_ASSISTANT_ROADMAP_CASE_TASK_NAME},
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "collaborators",
    name: EXECUTOR_ASSISTANT_COLLABORATORS_NAME,
    component: () => import("@/executor-assistant/contacts/CollaboratorsAndSuccessorsPage.vue"),
  },
  {
    path: "additional-tools",
    name: EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME,
    component: () => import("@/executor-assistant/additional-tools/pages/AdditionalToolsPage.vue"),
  },
  {
    path: "learn",
    component: () => import("@/executor-assistant/learn/LearnLayout.vue"),
    children: [
      {
        path: "",
        name: EXECUTOR_ASSISTANT_LEARN_NAME,
        component: () => import("@/executor-assistant/learn/LearnPage.vue"),
      },
      {
        path: ":caseTaskId",
        name: EXECUTOR_ASSISTANT_LEARN_ARTICLE_NAME,
        props: true,
        component: () => import("@/executor-assistant/learn/LearnArticle.vue"),
      },
    ],
  },
  {
    path: "care",
    name: EXECUTOR_ASSISTANT_GRIEF_CARE_NAME,
    component: () => import("@/executor-assistant/grief/GriefPage.vue"),
  },
  {
    path: "feedback",
    name: EXECUTOR_ASSISTANT_FEEDBACK_NAME,
    component: () => import("@/executor-assistant/feedback/FeedbackPage.vue"),
  },

  {
    path: "digital-vault",
    name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_CONTAINER_NAME,
    children: [
      {
        path: "",
        name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_NAME,
        component: () => import("@/executor-assistant/digital-vault/DigitalVaultLandingPage.vue"),
      },
      {
        path: ":section",
        name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME,
        component: () => import("@/executor-assistant/digital-vault/DigitalVaultSection.vue"),
        props: (to: RouteLocation) => ({
          ...to.params,
          caseId: +to.params.caseId,
        }),
      },
      {
        path: TSectionTypes.FUNERAL,
        name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_FUNERAL_NAME,
        component: () => import("@/executor-assistant/digital-vault/Funeral.vue"),
      },
      {
        path: TSectionTypes.PROFILE,
        name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_PROFILE_NAME,
        component: () => import("@/executor-assistant/digital-vault/Profile.vue"),
      },
      {
        path: TSectionTypes.LEGAL,
        name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_LEGAL_NAME,
        component: () => import("@/executor-assistant/digital-vault/Legal.vue"),
      },
    ],
  },
]
