import {type App, nextTick, watch} from "vue"
import {useCaseStore} from "@/library/stores/case"
import {useUserStore} from "@/library/stores/user"
import {createLogger} from "@/library/domain/logger"
import router from "@/router"
import type {RouteLocationNormalized} from "vue-router"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {compact, defaults, delay, isEmpty, omit} from "lodash"

export default async function setupGtag(app: App) {
  if (!import.meta.env.VITE_GA_TAG) {
    createLogger().info(
      "@/client/enduser/src/setup/setupGtag.ts",
      `Skipping initialization of GA due to absent VITE_GA_TAG`,
    )
    return
  }

  const gtag = fetchGtag()
  observeUserFor(gtag)
  observeCaseFor(gtag)
  router.afterEach(to => gtagWhenReady(gtag, to))
}

function fetchGtag() {
  defaults(window, {
    dataLayer: [],
    gtag() {
      // @ts-ignore
      window.dataLayer.push(arguments)
    },
  })

  // @ts-ignore
  window.gtag("js", new Date())

  const s = document.createElement("script")
  s.async = true
  s.src = `//www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GA_TAG}`
  document.body.appendChild(s)

  // @ts-ignore
  return window.gtag
}

async function gtagWhenReady(gtag: (...args: any[]) => void, to: RouteLocationNormalized) {
  if (!useUserStore().getToken()) {
    // base config invocation triggers page_track event
    gtag("config", import.meta.env.VITE_GA_TAG, {
      ...createPageConfigFor(to),
    })
    return
  }

  // ensure we have sufficient data for authed user
  await Promise.all([
    // useUserStore().fetchAuthUser(), // implicitly resolved; cases and members rely on user being authed
    useCaseStore().itemsFetchPromise,
    useCaseMembersStore().itemsFetchPromise,
  ])

  await nextTick() // ensure computed values have had a chance to resolve

  // base config invocation triggers page_track event
  delay(
    () =>
      gtag("config", import.meta.env.VITE_GA_TAG, {
        ...createPageConfigFor(to),
        is_authenticated: `${!!useUserStore().getToken()}`,
      }),
    2000, // brute force fallback for above nextTick() not waiting long enough for case and user observers
  )
}

function createPageConfigFor(to: RouteLocationNormalized) {
  // disambiguate screens unique by route param (eg. `executor-assistant-digital-vault-section {"section": "legal"}`
  const params = omit(to.params, "caseId")
  const screen_name = compact([to.name?.toString(), isEmpty(params) ? null : JSON.stringify(params)]).join(" ")
  return {
    // eg. executor-assistant-digital-vault-funeral-section
    page_title: screen_name,
    screen_name,
    // strip unique caseId from path to organically aggregate analytics
    page_path: to.path.replace(/^\/cases\/\d+/, "/cases/*"),
  }
}

function observeCaseFor(gtag: (...args: any[]) => void) {
  watch(
    () => useCaseStore().activeCase,
    activeCase => {
      gtag("set", {
        is_test: `${!!activeCase?.is_test}`,
        case_id: `${activeCase?.id || ""}`,
        case_status: `${activeCase?.status || ""}`,
        is_transitioned_case: `${!!activeCase?.meta.transitioned_to_aftercare_at}`,
      })
    },
    {immediate: true},
  )
}

function observeUserFor(gtag: (...args: any[]) => void) {
  watch(
    () => useUserStore().user,
    user => {
      gtag("set", {user_id: `${user?.id || ""}`})
    },
    {immediate: true},
  )

  watch(
    () => useCaseMembersStore().authUserMember,
    authUserMember => {
      gtag("set", {
        // produces something like `successor/collaborator` for successors w/ full access
        user_role: compact([
          `${authUserMember?.role || ""}`,
          authUserMember?.meta.is_collaborator ? "collaborator" : null,
        ]).join("/"),
      })
    },
    {immediate: true},
  )
}

export function track(eventName: string, context: IGTagEvent) {
  // @ts-ignore
  window.gtag("event", eventName, context)
}

export interface IGTagEvent {
  event_category: string
  event_action?: string
  event_label?: string
  event_value?: number
}
