import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const END_OF_LIFE_PATH = "final_wishes"

export const END_OF_LIFE_LABEL_MAP: LabelMap = new Map([
  ["final_wishes.dependant_children.has_dependant_children", ""],
  ["final_wishes.dependant_children.notes", ""],
  ["final_wishes.pets.has_pets", ""],
  ["final_wishes.pets.notes", ""],
  ["final_wishes.organ_donor.is_organ_donor", ""],
  ["final_wishes.organ_donor.notes", ""],
])
