import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const UTILITIES_PATH = "utility"

export const UTILITIES_LABEL_MAP: LabelMap = new Map([
  ["utility[*].type", ""],
  ["utility[*].name", ""],
  ["utility[*].id", ""],
  ["utility[*].attachment", ""],
  ["utility[*].notes", ""],
])
