import {AnalyticsBrowser} from "@segment/analytics-next"
import {createLogger} from "@/library/domain/logger"

// todo: migrate this to use setup*() pattern
const getAnalytics = () => {
  if (!import.meta.env.VITE_SEGMENT_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/segment.ts",
      "Skipping initialization of Segment due to missing VITE_SEGMENT_KEY",
    )

    return
  }

  return AnalyticsBrowser.load({writeKey: import.meta.env.VITE_SEGMENT_KEY})
}

const analytics = getAnalytics()

export default analytics
