import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const APPLICANT_LABEL_MAP: LabelMap = new Map([
  ["applicant.name", ""],
  ["applicant.address", ""],
  ["applicant.phone_alt", ""],
  ["applicant.phone", ""],
  ["applicant.place_of_birth", ""],
  ["applicant.role", ""],
  ["applicant.social_insurance_number", ""],
])