import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const PROPERTIES_PATH = "property"

export const PROPERTIES_LABEL_MAP: LabelMap = new Map([
  ["property[*].type", ""],
  ["property[*].name", ""],
  ["property[*].id", ""],
  ["property[*].attachment", ""],
  ["property[*].notes", ""],
])
