import type {ICaseMember} from "@/library/models/case-member.interface"
import type {IUser} from "./user.interface"
import type {ILocation} from "@/library/models/location.interface"
import type {IOrg} from "@/library/models/org.interface"
import type {ICaseTask} from "@/library/models/case-task.interface"
import type {Abilities} from "@/library/models/abilities.interface"
import type {ICaseFile} from "@/library/models/case-file.interface"

export enum CasePaymentStatus {
  PAYMENT_STATUS_PENDING = "pending",
  PAYMENT_STATUS_IN_PROGRESS = "in-progress",
  PAYMENT_STATUS_DONE = "done",
  PAYMENT_STATUS_FAILED = "failed",
}

export enum CaseStatus {
  STATUS_PRE_PLANNER = 1,
  STATUS_PRE_PLANNER_LOCKED = 2,
  STATUS_AFTER_CARE = 3,
  STATUS_AFTER_CARE_LOCKED = 4,
}

export enum CaseStatusWithTransitioned {
  STATUS_PRE_PLANNER = "preplan",
  STATUS_AFTER_CARE = "aftercare",
  STATUS_AFTER_CARE_TRANSITIONED = "transitioned",
}

export const CASE_ABILITIES = {
  ACCESS_PERSONAL_DATA: "accessPersonalData",
  VIEW_WILL_DATA: "viewWillData",
  VIEW_FINAL_WISHES_DATA: "viewFinalWishesData",
}

export interface ICase {
  id: number
  name: string
  first_name: string
  last_name: string
  status: CaseStatus
  is_test: boolean
  created_at: Date
  serviced_at: Date
  referred_at: Date
  paid: boolean
  payment_status: CasePaymentStatus
  creator?: IUser
  caseHandler?: IUser
  proof_of_death?: ICaseFile
  location?: ILocation
  org_id: IOrg["id"]
  org?: IOrg
  owner?: ICaseMember
  tasks?: ICaseTask[]
  tasks_count?: number
  enduser_custom_tasks_count?: number
  meeting_link?: string
  checkout_url?: string
  meta: {
    transitioned_to_aftercare_at?: string
    ownership_transfer_request_data?: ICaseTransitionMeta
  }
  __abilities?: Abilities
}

export interface ICaseTransitionMeta {
  name: string
  accepted: boolean
  requested_at: Date
  date_of_death: Date
  requester_user_id: IUser["id"]
}
