import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const ESTATE_REPS_LABEL_MAP: LabelMap = new Map([
  ["estate_reps[*].name", ""],
  ["estate_reps[*].email", ""],
  ["estate_reps[*].phone", ""],

  ["estate_reps[*].primary_relationship_to_deceased", ""],
  ["estate_reps[*].secondary_relationship_to_deceased", ""],

  ["estate_reps[*].address", ""],

  ["estate_reps[*].date_of_birth", ""],
  ["estate_reps[*].social_insurance_number", ""],
  ["estate_reps[*].photo_id", ""],
  ["estate_reps[*].proof_of_authority", ""],
])
