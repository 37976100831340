import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const DECEASED_PERSONAL_INFO_LABEL_MAP: LabelMap = new Map([
  ["deceased.name", ""],
  ["deceased.gender", ""],
  ["deceased.maiden_name", ""],
  ["deceased.name_at_birth", ""],
  ["deceased.date_of_birth", ""],
  ["deceased.place_of_birth", ""],
  ["deceased.ethnicity", ""],
  ["deceased.marital_status", ""],
])

export const DECEASED_CONTACT_INFO_LABEL_MAP: LabelMap = new Map([
  ["deceased.phone[*].phone_number", ""],
  ["deceased.email[*].email_address", ""],
  ["deceased.address[*].address_location", ""],
])

export const DECEASED_IMPORTANT_DOCS_LABEL_MAP: LabelMap = new Map([
  ["deceased.birth_certificate_location_hint", ""],
  ["deceased.citizenship_status", ""],
  ["deceased.social_insurance_number", ""],
  ["deceased.health_care_number", ""],
  ["deceased.military.discharge_document", ""],
])

export const DECEASED_PARENT_INFO_LABEL_MAP: LabelMap = new Map([
  ["deceased.mothers_name", ""],
  ["deceased.mothers_maiden_name", ""],

  ["deceased.fathers_name", ""],
  ["deceased.fathers_family_name", ""],
])

// @note - these isolated to and are flagged `irrelevantPathsForCaseState`
// See: `stores/mapped-app-field-key::isValidFormFillerPath(...)`
export const DECEASED_END_OF_LIFE_INFO_LABEL_MAP: LabelMap = new Map([
  ["deceased.date_of_death", ""],
  ["deceased.place_of_death", ""],
  ["deceased.cause_of_death", ""],
  ["deceased.proof_of_death", ""],
  // ["funeral.funeral_director", ""], // todo: doesn't exist
])

// const all_deceased = [
//     'deceased.mothers_maiden_name',
//     'deceased.mothers_name',
//     'deceased.name_at_birth',
//     'deceased.name',
//     'deceased.birth_certificate_location_hint',
//     'deceased.cause_of_death',
//     'deceased.citizenship_status',
//     'deceased.date_of_birth',
//     'deceased.date_of_death',
//
//     'deceased.place_of_birth',
//     'deceased.place_of_death',
//
//     'deceased.social_insurance_name',
//     'deceased.social_insurance_number',
//
//     'deceased.employment.notes',
//     'deceased.employment.status',
//     'deceased.ethnicity',
//     'deceased.fathers_family_name',
//     'deceased.fathers_name',
//     'deceased.health_care_number',
//     'deceased.maiden_name',
//
//     'deceased.email[*].email_address',
//     'deceased.email[*].type',
//
//     'deceased.address[*].address_location',
//     'deceased.address[*].type',
//
//     'deceased.marital_status',
//
//     'deceased.phone[*].phone_number',
//     'deceased.phone[*].service_provider',
//     'deceased.phone[*].type',
// ]
