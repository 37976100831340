import type {App} from "vue"
import type {Router} from "vue-router"
import * as Sentry from "@sentry/vue"
import {createLogger} from "@/library/domain/logger"
import {PRODUCTION, STAGING} from "@/library/domain/env"

export default function setupSentry(app: App, router: Router) {
  if (import.meta.env.VITE_SENTRY_AUTH_TOKEN) {
    Sentry.init({
      app,
      release: import.meta.env.VITE_RELEASE,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [new RegExp(`^${import.meta.env.VITE_API_BASE_URL}`)],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      trackComponents: true,
      tracesSampleRate: import.meta.env.MODE === PRODUCTION ? 0.1 : 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.MODE,
    })
  }
}
