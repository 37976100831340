import type {IAuthUser} from "@/library/models/auth-user.interface"
import {useUserStore} from "@/library/stores/user"

export default function useAuthUser(): IAuthUser {
  const authUser = useUserStore().user
  if (!authUser) {
    throw new Error("useAuthUser: auth user not found")
  }

  return authUser
}
