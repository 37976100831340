import {defineStore} from "pinia"

import type {IAppField, IValueOption} from "@/library/models/app-fields/app-field.interface"
import type {IBaseCollectionStoreState} from "@/library/stores/_base-collection"

import {chain, includes, keyBy, map} from "lodash"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import {DataModelItemApi} from "@/library/api/data-model-item"
import i18next from "i18next"

export const useAppFieldStore = defineStore("app-field", {
  state: (): IBaseCollectionStoreState<IAppField> => ({
    ...baseCollectionStoreState<IAppField>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<IAppField>(),
    appFields(state): IAppField[] | null {
      return state.items
    },
    appFieldsByPath(): {[s: IAppField["path"]]: IAppField} {
      return keyBy(this.appFields, "path")
    },
  },
  actions: {
    ...baseCollectionStoreActions<IAppField>(),
    async pullAppFields() {
      return this.cachedFetch(async () => patchValueOptionsOnto(await DataModelItemApi.fetch()))
    },
  },
})

function patchValueOptionsOnto(appFields: IAppField[]) {
  for (const field of appFields.filter(f => !!f.value_option_type)) {
    field.value_options = buildValueOptionsFor(field)
  }

  return appFields
}

function buildValueOptionsFor(appField: IAppField) {
  const labelsByValue = i18next.t(`app_fields:value_options.${appField.value_option_type}`) as unknown as Record<
    IValueOption["value"],
    IValueOption["label"]
  >

  return chain(labelsByValue)
    .omit(appField.meta?.value_option_blacklist || [])
    .map((label, value) => ({value, label}))
    .value() as IValueOption[]
}

export const getFieldValueOptionsLabelForValue = (appField: IAppField, value: string | number | undefined): string => {
  const valueOptions = appField?.value_options
  if (valueOptions && value) {
    const valueOptionsKeyedByValue = keyBy(valueOptions, "value")
    const valueOptionLabel = valueOptionsKeyedByValue[value] ? valueOptionsKeyedByValue[value].label : null

    if (valueOptionLabel) {
      return valueOptionLabel
    }

    if (value) {
      return String(value)
    }
  }
  return ""
}

export function getRootContainerFrom(path: IAppField["path"]): string {
  return path.split(/\.|\[\*]/)[0]
}
