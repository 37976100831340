import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const KEY_DOCUMENTS_PATH = "key_document"

export const KEY_DOCUMENTS_LABEL_MAP: LabelMap = new Map([
  ["key_document[*].type", ""],
  ["key_document[*].name", ""],
  ["key_document[*].id", ""],
  ["key_document[*].location_hint", ""],
  ["key_document[*].date_created", ""],
  ["key_document[*].last_updated", ""],
  ["key_document[*].attachment", ""],
  ["key_document[*].notes", ""],
])
