import type {RouteLocation, RouteLocationRaw} from "vue-router"
import {useUserStore} from "@/library/stores/user"
import {AUTH_LOGIN_NAME} from "@/auth/routes"

export function authGuard(to: RouteLocation): RouteLocationRaw | void {
  const userStore = useUserStore()
  if (!userStore.getToken()) {
    const route: RouteLocationRaw = {
      name: AUTH_LOGIN_NAME,
    }
    if (to.fullPath && to.fullPath !== "/") {
      route["query"] = {to: to.fullPath}
    }
    return route
  }
}
